import React from "react"
import * as PropTypes from "prop-types"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Ogp001EnPage extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>I'm playing Cookin' Burger!</title>
          <meta name="description" content="You can get DEAPcoin in the medal dropping game Cookin' Burger, a game that anyone can easily play! For those who are interested, let's play together!" />

          <meta property="og:image" content="https://www.cookinburger.com/img/ogp/ogp.png" />
          <meta property="og:title" content="I'm playing Cookin' Burger!" />
          <meta property="og:description" content="You can get DEAPcoin in the medal dropping game Cookin' Burger, a game that anyone can easily play! For those who are interested, let's play together!" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="I'm playing Cookin' Burger!" />
          <meta name="twitter:description" content="You can get DEAPcoin in the medal dropping game Cookin' Burger, a game that anyone can easily play! For those who are interested, let's play together!" />
          <meta name="twitter:image" content="https://www.cookinburger.com/img/ogp/ogp.png" />
          <meta http-equiv="Refresh" content="0;URL=https://www.cookinburger.com/" />
        </Helmet>
      </div>
    )
  }
}

Ogp001EnPage.propTypes = propTypes

export default Ogp001EnPage

export const pageQuery = graphql`
  query PageEnOgp01Query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`